import { StepEditorProps } from "../useStepEditor";
import { Block } from "../../../../components/atoms";
import { ErrorMessage, Field, Stack } from "@introist/react-foundation/v2";
import { prefixForm } from "../../../../utils/FormUtils";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { MeetingParticipantsEditor } from "./MeetingParticipants";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { ContentType } from "../../../AIAssistant/schema";
import { MeetingSchedule } from "./MeetingSchedule";
import { MeetingSettings } from "./MeetingSettings";
import { MeetingLocationEditor } from "./LocationEditor";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";

export const MeetingStepEditor = ({ form, stepDataPath, targetDateTerm }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Meeting time">
          <MeetingSchedule
            form={form}
            stepDataPath={stepDataPath}
            targetDateTerm={targetDateTerm}
          />
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <MeetingParticipantsEditor form={form} formPrefix={stepDataPath} />
      </Block>
      <Block blended compact>
        <MeetingLocationEditor form={form} formPrefix={stepDataPath} />
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Meeting details">
          <Stack vertical gap="xLarge">
            <Field title="Title">
              <FieldAwareInput
                size="small"
                placeholder="Title"
                value={prefixedForm.get("title")}
                onChange={prefixedForm.set("title")}
                error={prefixedForm.isError("title")}
              />
            </Field>
            <Field title="Description">
              <MessageEditor
                message={prefixedForm.get("description")}
                onChange={prefixedForm.set("description")}
                contentType={ContentType.MeetingDescription}
              />
              {prefixedForm.error("description") && (
                <ErrorMessage>{prefixedForm.error("description")}</ErrorMessage>
              )}
            </Field>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <MeetingSettings form={form} stepDataPath={stepDataPath} />
    </>
  );
};
