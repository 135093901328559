import { useMemo, useState } from "react";
import {
  Layout,
  TimeRangePicker,
  TimeRange,
  IPopoverProps,
  LabelledCheckbox
} from "@introist/react-foundation/v2";
import { TimeRangePickerProps } from "@introist/react-foundation/v2/02-blocks/TimePicker/TimeRangePicker";
import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";
import { IconToggleButton } from "../../IconToggleButton";

type PopupTimeRangePickerProps = TimeRangePickerProps &
  Pick<IPopoverProps, "attachToRef" | "placement" | "sameWidthWithReference"> & {
    allDayEnabled?: boolean;
  };

export const isAllDay = (range: TimeRange) => range.start === "00:00" && range.end === "00:00";

export const stringifyRange = (range: TimeRange) => {
  if (isAllDay(range)) return "All day";
  return `${range.start} - ${range.end}`;
};

export const PopupTimeRangePickerV2 = ({
  value,
  attachToRef,
  placement,
  sameWidthWithReference,
  allDayEnabled,
  ...rest
}: PopupTimeRangePickerProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const allDay = useMemo(() => !!allDayEnabled && isAllDay(value), [allDayEnabled, value]);

  return (
    <div ref={setRef}>
      <IconToggleButton active={open} startIcon="time" onClick={() => setOpen(!open)}>
        {stringifyRange(value)}
      </IconToggleButton>
      <CardPopover
        referenceElement={ref}
        attachToRef={attachToRef}
        placement={placement}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Layout.Group vertical>
          {!allDay && <TimeRangePicker value={value} {...rest} />}
          {allDayEnabled && (
            <LabelledCheckbox
              checked={allDay}
              label="All day"
              onChange={() => {
                if (allDay) {
                  rest.onSelect && rest.onSelect({ start: "10:00", end: "11:00" });
                } else {
                  rest.onSelect && rest.onSelect({ start: "00:00", end: "00:00" });
                }
              }}
            />
          )}
        </Layout.Group>
      </CardPopover>
    </div>
  );
};
