import { TriggerDayPicker } from "../../../../../components/molecules";

import {
  Icon,
  LabelledCheckbox,
  Select,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { TimeRangeEditorValue } from "./MeetingFlexEditor";
import { WeekdaySelector } from "./WeekdaySelector";

export const FlexRangeEditorPopover = ({
  value,
  onChange
}: {
  value?: TimeRangeEditorValue | null;
  onChange: (value: TimeRangeEditorValue | null) => void;
}) => {
  const { theme } = useTheme();

  return (
    <Stack vertical gap="xLarge">
      <LabelledCheckbox
        label="Use availability search"
        checked={!!value}
        onChange={checked => {
          if (checked) {
            onChange({
              start: { amount: 0, period: "days", side: "before" },
              end: { amount: 0, period: "days", side: "after" }
            });
          } else {
            onChange(null);
          }
        }}
      />

      <TriggerDayPicker
        startIcon="minusSmall"
        onChange={start => onChange({ ...value!, start })}
        trigger={value?.start ?? { amount: 0, period: "days", side: "before" }}
        disableSide
        disabled={!value}
        minAmount={0}
        supportHours
      />
      <Stack gap="large">
        <Icon name="calendar" color={theme.palette.foreground.subdued} />
        <Title>Meetings scheduled time</Title>
      </Stack>
      <TriggerDayPicker
        startIcon="plusSmall"
        onChange={end => onChange({ ...value!, end })}
        trigger={value?.end ?? { amount: 0, period: "days", side: "after" }}
        disableSide
        disabled={!value}
        minAmount={0}
        supportHours
      />
      <Stack vertical gap="small">
        <Title>Allowed weekdays</Title>
        <WeekdaySelector
          disabled={!value}
          onChange={allowedWeekdays => onChange({ ...value!, allowedWeekdays })}
          weekdays={value?.allowedWeekdays ?? ["1", "2", "3", "4", "5"]}
        />
      </Stack>

      <Stack vertical gap="small">
        <Title>If no available time is found</Title>
        <Select
          style={{ width: "100%" }}
          size="small"
          value={value?.failIfNoTime ? "true" : "false"}
          disabled={!value}
          options={[
            { key: "false", title: "Schedule to default time" },
            { key: "true", title: "Mark failing" }
          ]}
          onSelect={opt => onChange({ ...value!, failIfNoTime: opt.key === "true" })}
        />
      </Stack>
    </Stack>
  );
};
