import { IconToggleButton } from "../../../../workflows/components/IconToggleButton";
import { Icon, Stack, useTheme } from "@introist/react-foundation/v2";
import { CardPopover } from "../../../../workflows/routes/WorkflowEditor/CardPopover";
import { useMemo, useRef, useState } from "react";
import { RelativeDay } from "../../../../../services/api/WorkflowApi";
import { FlexRangeEditorPopover } from "./FlexRangeEditorPopover";

export type TimeRangeEditorValue = {
  start: RelativeDay;
  end: RelativeDay;
  failIfNoTime?: boolean;
  allowedWeekdays?: string[];
};

export type FindTimeRangeEditorProps = {
  value?: TimeRangeEditorValue | null;
  onChange: (value: TimeRangeEditorValue | null) => void;
};

export const MeetingFlexEditor = ({ value, onChange }: FindTimeRangeEditorProps) => {
  const { theme } = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const buttonText = useMemo(() => {
    if (!value) return "Set availability search";

    const start = `- ${value.start.amount} ${value.start.period}`;
    const end = `+ ${value.end.amount} ${value.end.period}`;

    return `${start} / ${end}`;
  }, [value]);

  return (
    <div>
      <IconToggleButton
        active={open}
        ref={buttonRef}
        onClick={() => setOpen(!open)}
        startAdornment={
          <Icon name="searchCircle" color={!value ? theme.palette.foreground.subdued : undefined} />
        }
      >
        <Stack gap="xSmall">
          {!value && <Icon name="plusSmall" color={theme.palette.foreground.subdued} />}
          <span style={{ color: !value ? theme.palette.foreground.subdued : undefined }}>
            {buttonText}
          </span>
        </Stack>
      </IconToggleButton>
      <CardPopover open={open} referenceElement={buttonRef.current} onClose={() => setOpen(false)}>
        <FlexRangeEditorPopover value={value} onChange={onChange} />
      </CardPopover>
    </div>
  );
};
